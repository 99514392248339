.experience-awards {
  --heading-size: 6.5vw;
  --experience-awards-body-delay: 2s;

  display: flex;
  padding: 120px var(--side-padding);

  @include tablet {
    display: block;

    padding: 80px var(--side-padding);
  }

  @include smartphone {
    --heading-size: 12vw;

    padding-top: 0;
  }

  &__column {
    flex: 50% 0 0;

    &:nth-of-type(2) {
      --heading-delay: 150ms;
      --experience-awards-body-delay: 2.2s;

      @include tablet {
        margin-top: 80px;
      }
    }

    &.is-activated {
      .experience-awards__body {
        opacity: 1;
        translate: 0 0 0;
        transition: opacity .5s var(--experience-awards-body-delay), translate .5s var(--experience-awards-body-delay) ease;
      }
    }
  }

  &__body {
    margin-top: 48px;
    opacity: 0;
    padding: 0 40px 0 3.9vw;
    translate: 0 20px 0;

    @include smartphone {
      padding-right: 0;
    }
  }

  &__company {
    @extend %t--h3;

    margin-bottom: 20px;

    &:not(:first-child) {
      margin-top: 64px;
    }

    .inline-link {
      color: var(--color-highlight);
      font-size: .6em;
      margin-left: 1em;
      margin-bottom: 0.4em;
    }
  }

  &__role {
    margin-top: 16px;
  }

  &__time {
    @extend %t--caption;

    margin-top: 2px;
  }

  &__award {
    height: 38px;
    margin-bottom: 48px;
    max-width: 100%;
    position: relative;
    transition: height .3s .2s ease;
    width: 460px;

    &__name {
      @extend %t--h3;
    }

    &__frame {
      border-radius: 28px;
      height: 56px;
      overflow: hidden;
      position: absolute;
      right: 0;
      top: -10px;
      transition: height .3s ease, width .3s ease, top .7s;
      width: 56px;

      .border {
        border-radius: 28px;
        scale: .999; // this fixes a weird safari bug
        transition: color .3s;

        &__rect {
          stroke-width: 8px;
          stroke-dasharray: 24, 4;
        }
      }
    }

    &__frame-inner {
      height: calc(100% - 8px);
      margin: 4px 0;
      overflow: hidden;
    }

    &__button {
      cursor: pointer;
      height: 56px;
      position: absolute;
      right: 0;
      top: 0;
      transition: color .3s;
      width: 56px;
      z-index: 1;

      &:focus {
        color: var(--color-tangerine);
      }

      @include desktop {
        &:hover {
          color: var(--color-tangerine);
        }
      }
    }

    &__expand,
    &__fold {
      left: 10px;
      position: absolute;
      rotate: 45deg;
      top: 10px;
      transition: opacity .2s .2s;
      width: 36px;
    }

    &__fold {
      opacity: 0;
    }

    @keyframes award-list-scroll {
      0% {
        translate: 0 0 0;
      }

      100% {
        translate: 0 -50% 0;
      }
    }

    &__list {
      @extend %t--quote;

      animation: award-list-scroll calc(var(--award-list-count) * .5s) paused linear infinite;
      height: calc(2 * var(--award-list-count) * var(--lineheight) * 1em);
      font-size: 18px;
      opacity: 0;
      padding: 0 24px;
      transition: opacity .4s, visibility 1ms .4s;
      visibility: hidden;

      @include smartphone {
        font-size: 14px;
      }
    }

    &__item {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: calc(100% - 20px);
    }

    &:not(.is-expanded) {
      .experience-awards__award {
        &__frame {
          @include desktop {
            &:hover {
              .border {
                color: var(--color-tangerine);
              }
            }
          }

          &:has(button:focus) {
            .border {
              color: var(--color-tangerine);
            }
          }
        }
      }
    }

    &.is-expanded {
      height: 200px;
      transition: height .3s ease;

      .experience-awards__award {
        &__frame {
          height: 156px;
          top: 46px;
          transition: height .4s .3s ease, width .5s .2s ease, top .7s;
          width: 100%;
        }

        &__list {
          animation-play-state: running;
          opacity: 2;
          transition: opacity .4s .6s;
          visibility: visible;
        }

        &__expand {
          opacity: 0;
        }

        &__fold {
          opacity: 1;
        }
      }
    }
  }
}