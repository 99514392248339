@mixin above-grid {
  @media screen and (min-width: 1621px) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: 1025px) {
    @content;
  }
}

@mixin above-smartphone {
  @media screen and (min-width: 769px) {
    @content;
  }
}

@mixin tablet {
  @media screen and (max-width: 1024px) {
    @content;
  }
}

@mixin smartphone {
  @media screen and (max-width: 768px) {
    @content;
  }
}
