.portfolio {
  &__ballpool {
    --aspect-ratio: .5;
    --ball-size: .1;

    aspect-ratio: var(--aspect-ratio);
    bottom: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    pointer-events: none;
    transition: opacity .5s, visibility 1ms .5s;
    width: 100%;
    visibility: hidden;

    @include tablet {
      --ball-size: .12;
    }

    @include smartphone {
      --ball-size: .2;
    }

    &.is-activated {
      opacity: 1;
      transition: opacity .5s;
      visibility: visible;
    }

    &:not(.is-activated) {
      overflow: hidden;
    }

    &.is-exiting {
      .portfolio__ballpool__ball:not(.is-moving-out) {
        opacity: 0;
        transition: opacity .8s;
      }
    }

    &__ball {
      background-color: var(--color-white);
      aspect-ratio: 1 / 1;
      border-radius: 50%;
      left: calc(-100% * var(--ball-size) / 2);
      overflow: hidden;
      opacity: 0;
      position: absolute;
      pointer-events: auto;
      transition: opacity .2s, scale .2s ease;
      top: calc(-100vw * var(--ball-size) / 2);
      scale: .5;
      width: calc(100% * var(--ball-size));

      &__logo,
      &__overlay {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }

      &__overlay {
        animation: rotate 5s infinite linear;
        background-color: rgba(var(--color-blue-rgb), .9);
        opacity: 0;
        transition: opacity .5s;
      }

      &__svg {
        display: block;
        width: 100%;
      }

      &__text {
        @extend %t--caption;
      }

      &--placeholder {
        scale: 1 !important;
        visibility: hidden;
      }

      &.is-moving-out {
        transition:
          opacity .3s 1.5s,
          scale .6s calc(1.2s + .1s * var(--project-index)) ease,
          rotate calc(.3s + .2s * var(--project-index)) .7s ease,
          translate calc(1s + .05s * var(--project-index)) .7s ease;
      }

      &.is-activated {
        opacity: 1;
        scale: 1;
      }

      &:not(.is-moving-out):hover &__overlay {
        opacity: 1;
      }
    }
  }
}
