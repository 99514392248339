.hero {
  padding: 120px 0;

  @include desktop {
    // heading height is 36.63vw
    padding: calc((100vh - 36.63vw) / 2) 0 200px;
    min-height: 100vh;
  }

  &__body {
    @extend %t--h2;

    margin-top: 140px;
    opacity: 0;
    translate: 0 1em;
    padding: 0 var(--side-padding);
    transition: opacity .5s 2.5s, translate .5s 2.5s ease;
    max-width: var(--max-paragraph-width);

    .name {
      color: var(--color-highlight);
      display: inline-block;
      position: relative;

      &__name {
        transition: opacity .4s;
      }

      &__pronunciation {
        cursor: help;
        display: block;
        left: 0;
        opacity: 0;
        position: absolute;
        right: 0;
        top: 0;
        transition: opacity .2s, visibility 1ms .2s;
        text-align: center;
        visibility: hidden;
      }

      &:hover {
        .name__name {
          opacity: 0;
          transition: opacity .2s, visibility 1ms .2s;
          visibility: hidden;
        }

        .name__pronunciation {
          opacity: 1;
          transition: opacity .4s;
          visibility: visible;
        }
      }
    }

    a {
      color: var(--color-highlight);
    }
  }

  &.is-activated {
    .hero__body {
      opacity: 1;
      translate: 0 0;
    }
  }
}