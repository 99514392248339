@font-face {
  font-family: 'Noto Sans SC';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(../static/fonts/NotoSansSC-Bold-subset.otf) format('opentype');
}

@font-face {
  font-family: 'Faro Test';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(../static/fonts/FaroDisplayVariableWeb.woff2) format('woff2');
}

@font-face {
  font-family: 'Codec Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../static/fonts/Codec-Pro-Regular.woff2) format('woff2');
}

@font-face {
  font-family: 'Codec Pro';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(../static/fonts/Codec-Pro-Bold.woff2) format('woff2');
}

@font-face {
  font-family: 'Founders Grotesk Mono';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../static/fonts/founders-grotesk-mono-regular.woff2) format('woff2');
}

@font-face {
  font-family: 'Founders Grotesk Mono';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(../static/fonts/founders-grotesk-mono-medium.woff2) format('woff2');
}

:root {
  --font-body: 'Codec Pro', sans-serif;
  --font-display: 'Faro Test', sans-serif;
  --font-sans-cn: 'Noto Sans SC', sans-serif;
  --font-mono: 'Founders Grotesk Mono', monospace;
}

body {
  @extend %t--body;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

%t--h1 {
  --lineheight: 1.1;

  font-family: var(--font-display);
  font-feature-settings: 'liga';
  font-variation-settings: 'mood' 1;
  font-weight: 700;
  line-height: var(--lineheight);

  @include smartphone {
    font-size: 32px;
  }
}

%t--h2 {
  font-size: 33px;
  font-weight: 500;
  line-height: 1.4;
  letter-spacing: -.0025em;

  @include smartphone {
    font-size: 24px;
  }
}

%t--h3 {
  font-size: 32px;
  font-weight: 400;
  line-height: 1.2;

  @include smartphone {
    font-size: 24px;
  }
}

%t--h4 {
  font-size: 26px;
  font-weight: 400;
  line-height: 1.05;

  @include tablet {
    font-size: 22px;
  }

  @include smartphone {
    font-size: 20px;
  }
}

%t--body {
  font-family: var(--font-body);
  font-size: 20px;
  font-weight: 400;
  line-height: 1.45;
  letter-spacing: .02em;

  @include smartphone {
    font-size: 16px;
  }
}

%t--small {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.45;
  letter-spacing: .03em;

  @include smartphone {
    font-size: 16px;
  }
}

%t--caption {
  font-family: var(--font-mono);
  font-size: 14px;
  font-weight: 500;
  line-height: 1.45;
  letter-spacing: .04em;
  text-transform: uppercase;
}

%t--quote {
  --lineheight: 1.272727;

  font-family: var(--font-mono);
  font-size: 22px;
  font-weight: 400;
  line-height: var(--lineheight);
  letter-spacing: -.0025em;

  @include tablet {
    font-size: 18px;
  }

  @include smartphone {
    font-size: 16px;
  }
}
