:root {
  --color-white: #fff;
  --color-white-rgb: 255, 255, 255;
  --color-black: #280000;
  --color-black-rgb: 40, 0, 0;
  --color-purple: #7e6bF4;
  --color-purple-light: #c5bcff;
  --color-tangerine: #ff9a8c;
  --color-tangerine-light: #ffe6d8;
  --color-blue: #a0f9ff;
  --color-blue-rgb: 160, 249, 255;
  --color-magenta: #ef5da8;
  --color-magenta-rgb: 239, 93, 168;

  --color-front: var(--color-black);
  --color-back: var(--color-purple);
  --color-highlight: var(--color-tangerine);

  --heading-size: 16vw;
  --heading-delay: 0ms;

  --side-padding: calc(80 / 1440 * 100vw);
  --max-paragraph-width: 880px;

  @include smartphone {
    --side-padding: 20px;
  }
}
