.portfolio {
  &__list-container {
    // align-items: center;
    // display: flex;
    flex-grow: 1;
    width: 100%;
  }

  &__list {
    height: var(--portfolio-bubble-size);
    opacity: 0;
    transition: opacity .8s, visibility 1ms .8s, scale .8s ease;
    position: relative;
    pointer-events: auto;
    visibility: hidden;
    will-change: translate;
    width: 100%;

    &.is-activated {
      opacity: 1;
      transition: opacity .8s, scale .8s ease;
      visibility: visible;
    }

    &.is-exiting {
      opacity: 0;
      transition: opacity .3s, scale .4s ease;
      scale: 0;
    }
  }

  &__project {
    position: absolute;
    top: 0;
    width: var(--portfolio-bubble-size);

    &.is-moving {
      transition: left .5s ease;
    }

    &__bubble {
      aspect-ratio: 1 / 1;
      background: var(--color-white);
      border-radius: 50%;
      position: relative;
      transition: scale var(--portfolio-bubble-duration) ease;
      width: 100%;

      &::before {
        background: linear-gradient(rgba(var(--color-white-rgb), .7), rgba(var(--color-white-rgb), 0) 50%);
        border-radius: 50%;
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        pointer-events: none;
        top: 0;
        transition: opacity .3s;
        width: 100%;
        z-index: 1;
      }

      &__media {
        border-radius: 50%;
        left: 0;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        width: 100%;
      }

      &__meta {
        @extend %t--h4;

        color: var(--color-white);
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        left: 0;
        height: 100%;
        padding: 10% 15% 15%;
        position: absolute;
        top: 0;
        transition: background-color .5s, translate .5s ease;
        width: 100%;
        z-index: 2;

        @include tablet {
          padding: 15% 10%;
        }

        &__name {
          color: var(--color-black);
          font-size: .9em;
          transition: color .3s;
          padding: 0 8%;
        }

        &__description {
          opacity: 0;
          transition: opacity .5s;
        }
      }

      &.is-selected {
        scale: var(--portfolio-bubble-scale);
        z-index: 1;

        .portfolio__project__bubble__image,
        .portfolio__project__bubble__meta,
        &::before {
          opacity: 0;
          transition: opacity .2s;
        }
      }

      &.is-hidden {
        visibility: hidden;
      }

      &:hover,
      &:focus {
        .portfolio__project__bubble__meta {
          background: var(--color-magenta);
          translate: 0 20% 0;

          &__name {
            color: var(--color-white);
          }

          &__description {
            opacity: 1;
          }
        }
      }
    }

    &__dialog {
      --dialog-gutter: 28px;
      background: var(--color-white);
      border: 0;
      border-radius: 76px;
      color: var(--color-black);
      height: calc(100% - 30px);
      left: 15px;
      margin: 0;
      max-height: none;
      max-width: none;
      overflow: hidden;
      padding: 0;
      top: 15px;
      transition: background-color var(--color-transition), color var(--color-transition);
      width: calc(100% - 30px);

      &::backdrop {
        opacity: 0;
      }

      &.is-entering {
        // do better easing
        transition:
          left .4s ease,
          top .4s ease,
          border-radius 1s .4s ease,
          width 1s .4s ease,
          height 1s .4s ease,
          background-color var(--color-transition),
          color var(--color-transition);

        .portfolio__project__dialog__close,
        .portfolio__project__dialog__content,
        .border {
          opacity: 1;
          rotate: 0deg;
          transition:
            opacity .5s 1.4s,
            background-color var(--color-transition),
            color var(--color-transition);
        }

        .portfolio__project__dialog__close {
          &:hover,
          &:focus {
            rotate: 90deg;
            transition:
              opacity .5s 1.4s,
              rotate .4s ease,
              background-color var(--color-transition),
              color var(--color-transition);
          }
        }
      }

      &.is-exiting {
        opacity: 0;
        transition:
          left 1s ease-in,
          top .7s ease,
          border-radius .4s ease,
          width .4s ease,
          height .4s ease,
          opacity .4s .6s,
          background-color var(--color-transition),
          color var(--color-transition);

        &::backdrop {
          animation: backdrop-in 1s reverse linear both;
        }
      }

      .border {
        color: var(--color-purple-light);
        opacity: 0;
        transition: opacity .3s;
        z-index: 1;
      }

      &__close {
        align-items: center;
        background-color: var(--color-white);
        border-radius: 50%;
        color: var(--color-black);
        display: flex;
        height: 60px;
        justify-content: center;
        opacity: 0;
        position: absolute;
        right: var(--dialog-gutter);
        top: var(--dialog-gutter);
        transition: background-color var(--color-transition), color var(--color-transition), opacity .3s;
        width: 60px;
        z-index: 1;

        svg {
          width: 28px;
        }

        @include smartphone {
          height: 50px;
          width: 50px;

          svg {
            width: 24px;
          }
        }
      }

      &__content {
        display: grid;
        grid-gap: var(--dialog-gutter);
        grid-template-columns: repeat(6, minmax(0, 1fr));
        height: 100%;
        margin: 0 3px;
        opacity: 0;
        overflow-y: auto;
        overscroll-behavior: contain;
        padding: 120px var(--dialog-gutter);
        transition: opacity .3s;
        -webkit-overflow-scrolling: touch;

        /* Firefox */
        scrollbar-width: auto;
        scrollbar-color: var(--color-purple-light) var(--color-white);

        /* Chrome, Edge, and Safari */
        &::-webkit-scrollbar {
          width: 16px;
        }

        &::-webkit-scrollbar-track {
          background: var(--color-white);
        }

        &::-webkit-scrollbar-thumb {
          background-color: var(--color-purple-light);
          border-radius: 10px;
          border: 3px solid var(--color-white);
        }
      }

      &__title {
        @extend %t--h2;

        grid-column: 1 / -2;

        @include smartphone {
          grid-column: 1 / -1;
        }
      }

      &__body {
        grid-column: span 3;

        @include smartphone {
          grid-column: 1 / -1;
        }

        p {
          margin-bottom: .5em;
        }
      }

      &__metas {
        grid-column: 5 / -1;

        @include smartphone {
          grid-column: 1 / -1;
        }
      }

      &__meta__title {
        @extend %t--caption;
      }

      &__meta__body {
        @extend %t--small;

        margin-bottom: 12px;
      }

      &__meta__links {
        @extend %t--small;

        margin-bottom: 12px;

        a.portfolio__project__dialog__meta__link {
          display: block;
          margin-bottom: 6px;
          width: fit-content;

          &::after {
            border-color: var(--color-purple);
            display: block;
          }
        }
      }

      &__media {
        --project-media-col: 6;
        --project-media-push: 0;

        grid-column: auto / span var(--project-media-col);

        &--has-push {
          grid-column: calc(var(--project-media-push) + 1) / span var(--project-media-col);
        }

        @include smartphone {
          grid-column: 1 / -1;
        }

        img,
        video {
          display: block;
          object-fit: cover;
          min-height: 100%;
          width: 100%;
        }
      }
    }
  }
}