.heading {
  @extend %t--h1;

  --en-height: calc(var(--lineheight) * var(--heading-size));

  font-size: var(--heading-size);
  font-variation-settings: 'mood' 1;
  overflow: hidden;
  position: relative;
  text-align: center;

  &:not(.is-enabled) {
    opacity: 0;
  }

  &__char {
    display: inline-block;

    &--e,
    &--c,
    &--s {
      font-variation-settings: 'mood' 50;
    }

    &--tf {
      margin: 0 -.019em 0 .03em;
    }
  }

  &__en {
    display: block;
    height: var(--en-height);
    position: absolute;
    top: 50%;
    width: max-content;

    &__inner {
      display: block;
      height: var(--en-height);
    }

    &__top,
    &__bottom {
      overflow: hidden;
    }

    &__top {
      display: block;
      height: calc(.5 * var(--en-height) + 1px);
      position: relative;

      .heading__char {
        translate: 0 calc(.5 * var(--en-height)) 0;
      }
    }

    &__bottom {
      bottom: 0;
      display: block;
      height: calc(.5 * var(--en-height));
      left: 0;
      position: absolute;
      width: 100%;

      .heading__char {
        translate: 0 calc(-.5 * var(--en-height)) 0;
      }

      .heading__en__inner {
        bottom: 0;
        position: absolute;
      }
    }

    .heading__char {
      opacity: 0;
    }

    &::after {
      background: var(--color-front);
      content: '';
      height: .1em;
      left: 0;
      position: absolute;
      top: calc(50% - .05em);
      width: 0;
    }
  }

  &__cn {
    color: transparent;
    display: block;
    font-family: var(--font-sans-cn);
    font-size: 2.2em;
    font-weight: 700;
    line-height: 1.1;
    margin-top: -.1em;
    position: relative;
    pointer-events: none;
    transition: -webkit-text-stroke-color var(--color-transition);
    white-space: pre;
    width: fit-content;
    -webkit-text-stroke: .01em var(--color-highlight);

    .heading__char {
      opacity: 0;
    }

    ::selection {
      -webkit-text-stroke: .01em var(--color-highlight);
    }
  }

  &:not(&--left-aligned) {
    .heading__en {
      left: 50%;
      translate: -50% -50%;
    }

    .heading__cn {
      left: 50%;
      translate: -50% 0 0;
    }
  }

  &--left-aligned {
    .heading__en {
      left: 0;
      translate: 0 -50%;
    }

    .heading__cn {
      left: .25em;
    }
  }

  @keyframes heading-cn-char-in {
    0% {
      opacity: 0;
      translate: 0 100% 0;
    }

    100% {
      opacity: 1;
      translate: 0 0 0;
    }
  }

  @keyframes heading-cn-bar {
    0% {
      width: 0;
    }

    70% {
      background: var(--color-front);
      opacity: 1;
      scale: 1 1;
      width: 100%;
      animation-timing-function: ease;
    }

    100% {
      background: var(--color-highlight);
      opacity: .3;
      scale: 1.1 0;
      width: 100%;
    }
  }

  @keyframes heading-en-wave {
    0%, 10%, 20%, 25%, 32% {
      animation-timing-function: ease-in;
      font-variation-settings: 'mood' 1;
    }

    5%, 23%, 28% {
      animation-timing-function: ease-out;
      font-variation-settings: 'mood' 50;
    }
  }

  &[data-heading-step="0"] {
    overflow: visible;

    .heading__cn {
      .heading__char {
        animation: heading-cn-char-in .6s calc(var(--char-index) * .05s + var(--heading-delay)) both ease;
      }
    }
  }

  &[data-heading-step="1"] {
    .heading__cn {
      opacity: .7;
      transition: opacity .8s;

      .heading__char {
        opacity: 1;
      }
    }

    .heading__en {
      .heading__char {
        translate: 0 0 0;
        transition: translate .7s ease;
        transition-delay: calc(.5s + var(--char-index) * .05s + var(--heading-delay));
        opacity: 1;
      }

      &::after {
        animation: heading-cn-bar .9s calc(.2s + var(--heading-delay)) both ease;
      }
    }
  }

  &[data-heading-step="2"] {
    .heading__cn {
      opacity: .7;

      .heading__char {
        opacity: 1;
      }
    }

    .heading__en {
      .heading__char {
        translate: 0 0 0;
        opacity: 1;

        &--E,
        &--tf,
        &--Z {
          animation: heading-en-wave 5s infinite;
        }
      }

      &__top {
        height: var(--en-height);
      }

      &__bottom {
        display: none;
      }
    }
  }
}