@keyframes rotate {
  0% {
    rotate: 0deg;
  }

  33.333% {
    rotate: 120deg;
  }

  66.667% {
    rotate: 240deg;
  }

  100% {
    rotate: 360deg;
  }
}