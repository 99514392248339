.footer {
  --heading-size: 12vw;
  // --heading-delay: 300ms;

  padding: 120px 0 240px;
  overflow: hidden;

  &__container {
    padding: 0 calc(var(--side-padding) * 2) 0 var(--side-padding);
    position: relative;
  }

  .heading {
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &__title {
    @extend %t--h1;

    font-size: 8vw;
    margin-bottom: 96px;
    opacity: 0;
    padding-top: .8em;
    position: relative;
    translate: 0 .5em 0;
    transition: translate .5s ease, opacity .5s;

    span {
      font-variation-settings: 'mood' 50;
    }

    @include smartphone {
      font-size: 12vw;
    }
  }

  &__list {
    display: flex;
    gap: 48px;
  }

  &__link {
    opacity: 0;
    transition: opacity .8s;

    &:nth-child(1) {
      transition-delay: 800ms;
    }

    &:nth-child(2) {
      transition-delay: 900ms;
    }

    &:nth-child(3) {
      transition-delay: 1000ms;
    }
  }

  &.is-activated {
    .footer__title,
    .footer__link {
      opacity: 1;
      translate: 0 0 0;
    }
  }
}