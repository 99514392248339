@import 'ballpool';
@import 'case-studies';

:root {
  --sqrt3: 1.7320508;
}

.portfolio {
  --portfolio-bubble-per-row: 3;
  --portfolio-bubble-scale: 1.1;
  // since these are used in js, times need to be in ms
  --portfolio-bubble-duration: 200ms;
  padding: 120px 0;

  @include smartphone {
    --portfolio-bubble-per-row: 1.5;
    padding-bottom: 0;
  }

  @include above-grid {
    --portfolio-bubble-per-row: 4;
  }

  &__container {
    height: 250vh;
    position: relative;
  }

  &__color {
    position: absolute;
    top: 50%;
    width: 100%;
  }

  &__sticky {
    height: 100vh;
    position: sticky;
    top: 0;
  }

  &__slide {
    display: flex;
    flex-direction: column;
    height: 100%;
    left: 0;
    position: absolute;
    pointer-events: none;
    top: 0;
    width: 100%;

    &__title {
      @extend %t--h2;

      padding: 0 var(--side-padding);
      text-align: center;
      margin: 120px auto;
      max-width: 800px;
      opacity: 0;
      translate: 0 1em 0;
      pointer-events: auto;
      position: relative;
      transition: opacity .2s, translate 1ms .2s ease, visibility 1ms .2s;
      visibility: hidden;
      z-index: 1;

      @include smartphone {
        margin: 60px auto 120px;
      }

      &.is-activated {
        opacity: 1;
        translate: 0 0 0;
        transition: opacity .8s, translate .8s ease;
        visibility: visible;
      }
    }

    &--case-studies {
      overflow: hidden;
    }
  }
}