html,
body {
  --color-transition: .8s;

  box-sizing: border-box;
  scroll-behavior: smooth;
  scroll-snap-type: x proximity;
}

body {
  background: var(--color-back);
  color: var(--color-front);
  transition: background-color var(--color-transition), color var(--color-transition), filter .3s;

  &.is-modal-open {
    filter: blur(3px);
    transition: background-color var(--color-transition), color var(--color-transition), filter .3s .5s;
  }

  // TODO: reveal after font load

  @keyframes grain {
    0% , to{
      transform:translate(0)
    }

    10% {
      transform:translate(-5%, -10%);
    }

    20% {
      transform:translate(-15%, 5%);
    }

    30% {
      transform:translate(7%, -25%);
    }

    40% {
      transform:translate(-5%, 25%);
    }

    50% {
      transform:translate(-15%, 10%);
    }

    60% {
      transform:translate(15%);
    }

    70% {
      transform:translateY(15%);
    }

    80% {
      transform:translate(3%, 35%);
    }

    90% {
      transform:translate(-10%, 10%);
    }
  }
  &::before {
    animation: grain 5s steps(10) infinite;
    background: url(../static/images/grain.png) 50% / 150px repeat;
    bottom: -100%;
    content: "";
    left: -100%;
    opacity: .04;
    position: fixed;
    pointer-events: none;
    right: -100%;
    top: -100%;
  }
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

button {
  appearance: none;
  background: transparent;
  border: none;
  color: inherit;
  margin: 0;
  overflow: visible;
  padding: 0;
  width: auto;
}

a {
  text-decoration: none;
}

p a,
a.inline-link {
  display: inline-block;
  position: relative;
  overflow: hidden;
  vertical-align: bottom;

  &::after {
    border-top: 2px dashed var(--color-highlight);
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    transition: translate .5s ease, border-color var(--color-transition);
    width: 200%;
  }

  @include desktop {
    &:hover,
    &:focus {
      &::after {
        translate: -50% 0;
      }
    }
  }
}

i {
  font-style: italic;
}
