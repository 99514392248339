.beliefs {
  margin-top: 200px;

  @include smartphone {
    margin-top: 100px;
  }

  &__title {
    @extend %t--h2;

    margin-bottom: 120px;
    max-width: var(--max-paragraph-width);
    opacity: 0;
    padding: 0 var(--side-padding);
    translate: 0 1em;

    &.is-activated {
      opacity: 1;
      translate: 0 0;
      transition: opacity .5s, translate .5s ease;
    }

    @include smartphone {
      margin-bottom: 60px;
    }
  }

  &__list {
    height: 90vh;
    height: 90dvh;
    position: relative;
    margin: 0 auto;
    width: min(calc(100% - 2 * var(--side-padding)), calc(1440px - 2 * var(--side-padding)));
    z-index: 0;

    &.is-activated {
      .beliefs__quote {
        animation: belief-enter .6s calc(.4s + (var(--beliefs-total) - var(--beliefs-distance)) * 60ms) backwards;
        opacity: 1;
      }
    }
  }

  @keyframes belief-enter {
    0% {
      opacity: 0;
      translate: calc((25 - var(--quote-left)) * .5vw) calc((45 - var(--quote-top)) * .5vh) 0;
      scale: 0;
    }

    60% {
      opacity: 1;
      scale: 1.1;
      translate: 0 0 0;
    }

    85% {
      scale: .98;
    }

    100% {
      scale: 1;
    }
  }

  &__quote {
    @extend %t--quote;

    background-color: color-mix(in srgb, var(--color-back) calc(50% * var(--beliefs-distance) / var(--beliefs-total)), var(--color-purple-light));
    border-radius: 44px;
    color: var(--color-black);
    filter: blur(calc(0.3px * var(--beliefs-distance)));
    left: calc(1% * var(--quote-left));
    max-width: min(calc(100% - 1% * var(--quote-left)), 43%);
    opacity: 0;
    padding: 30px 56px;
    position: absolute;
    text-align: center;
    top: calc(1% * var(--quote-top));
    transition:
      background-color .3s,
      border-radius .5s,
      filter .5s,
      scale .3s;
    z-index: calc(var(--beliefs-total) - var(--beliefs-distance));

    @include tablet {
      padding: 20px 36px;
    }

    @include smartphone {
      left: calc(.5% * var(--quote-left));
      max-width: min(calc(100% - .5% * var(--quote-left)), 83%);

      .border__rect {
        stroke-width: 8px;
      }
    }

    &__highlight {
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }

    &__text {
      position: relative;
    }

    .border {
      border-radius: 76px;
      color: var(--color-purple-light);
      opacity: 0;
      transition:
        opacity .3s;
    }

    &.is-front {
      background-color: var(--color-white);
      border-radius: 76px;
      scale: 1.1;
      transition:
        background-color .3s .2s,
        border-radius .5s,
        filter .5s,
        scale .3s cubic-bezier(.82,1.68,.58,.99);

      .border {
        opacity: 1;
        transition:
          opacity .3s .2s;
      }
    }
  }
}
